<template>
  <div>
    <div class="container-dev-detail">
      <div class="top d-f a-c j-b">
        <div>
          <img src="@/assets/img/logo.png" class="logo" />
          <span class="title">小牛智慧校园云平台</span>
          <span class="current-page">终端详情页</span>
        </div>
        <div @click="back">
          <img src="@/assets/img/dev-detail-back.png" alt="" />
          <span class="back">返回</span>
        </div>
      </div>
      <div class="space d-f f-d">
        <div class="d-f j-b a-c">
          <div class="params d-f a-c">
            <span class="devName">{{ buildingName + "-" + classroomName }}</span>
            <span class="serialNum">（序列号：{{ serialNum }}）</span>
          </div>
          <div class="d-f directive">
            <div class="off d-f a-c j-c" @click="off">
              <img src="@/assets/img/detail2.png" alt="" />
              <p class="d-f j-c a-c">关机</p>
            </div>
            <div class="reon d-f a-c j-c" @click="reon">
              <img src="@/assets/img/detail3.png" alt="" />
              <p>重启</p>
            </div>
            <div class="sleep d-f a-c j-c" @click="sleep">
              <img src="@/assets/img/detail4.png" alt="" />
              <p>息屏</p>
            </div>
            <div class="light d-f a-c j-c" @click="light">
              <img src="@/assets/img/detail5.png" alt="" />
              <p>亮屏</p>
            </div>
          </div>
        </div>
        <ul class="big-ul d-f j-b">
          <li>
            <div class="cpu d-f a-c">CPU使用率</div>
            <div class="line"></div>
            <div class="liquidfill-contain d-f a-c j-c">
              <div class="liquidfill" ref="cpu"></div>
            </div>
            <div class="no-data d-f f-d a-c j-c" v-if="noData">
              <img src="@/assets/img/dev_detail_nodata.png" alt="" />
              <p>暂无信息</p>
            </div>
          </li>
          <li>
            <div class="cpu d-f a-c">内存使用率</div>
            <div class="line"></div>
            <div class="liquidfill-contain d-f a-c j-c">
              <div class="liquidfill" ref="ram"></div>
            </div>
            <div class="no-data d-f f-d a-c j-c" v-if="noData">
              <img src="@/assets/img/dev_detail_nodata.png" alt="" />
              <p>暂无信息</p>
            </div>
          </li>
          <li>
            <div class="cpu d-f a-c">硬盘使用率</div>
            <div class="line"></div>
            <div class="liquidfill-contain d-f a-c j-c">
              <div class="liquidfill" ref="hardDisk"></div>
            </div>
            <div class="no-data d-f f-d a-c j-c" v-if="noData">
              <img src="@/assets/img/dev_detail_nodata.png" alt="" />
              <p>暂无信息</p>
            </div>
          </li>
        </ul>
        <div class="detail d-f j-b">
          <div class="left">
            <div class="left-top d-f a-c">设备基本信息</div>
            <div class="line"></div>
            <table>
              <tr>
                <td>内存：</td>
                <td>{{ obj.windows.memory }}</td>
              </tr>
              <tr>
                <td>显卡信息：</td>
                <td>{{ obj.windows.graphicsCard }}</td>
              </tr>
              <tr>
                <td>cpu：</td>
                <td>{{ obj.windows.cpu }}</td>
              </tr>
              <tr>
                <td>硬盘：</td>
                <td>{{ obj.windows.disk }}</td>
              </tr>
              <tr>
                <td>版本信息：</td>
                <td>
                  {{ obj.windows.versionInfo }}
                  <!-- <span class="check-update" @click="checkUpdate">检测升级</span> -->
                </td>
              </tr>
            </table>
            <div class="no-data-too d-f f-d a-c j-c" v-if="noData">
              <img src="@/assets/img/dev_detail_nodata.png" alt="" />
              <p>暂无信息</p>
            </div>
          </div>
          <div class="right">
            <div class="right-top d-f a-c">安卓系统信息</div>
            <div class="line"></div>
            <table>
              <tr>
                <td>运行内存：</td>
                <td>{{ obj.android.memory }}</td>
              </tr>
              <tr>
                <td>存储空间：</td>
                <td>{{ obj.android.storageSpace }}</td>
              </tr>
              <tr>
                <td>MAC地址：</td>
                <td>{{ obj.android.macAddress }}</td>
              </tr>
              <tr>
                <td>设备编号：</td>
                <td>{{ obj.android.serialNum }}</td>
              </tr>
              <tr>
                <td>Android版本：</td>
                <td>{{ obj.android.androidVersion }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="icp d-f a-c j-c">
        ©2021 XNIU 苏公网安备 32011502011289号 苏ICP备2020065401号
        江苏小牛电子科技有限公司
      </div>
    </div>
  </div>
</template>

<script>
import echartsLiquidfill from "echarts-liquidfill";
import echarts from "echarts";
export default {
  data() {
    return {
      value1: 0.25,
      value2: 0.68,
      value3: 0.5,
      websocket: null,
      timer: null,
      obj: {
        windows: {
          memory: "",
          graphicsCard: "",
          cpu: "",
          disk: "",
          versionInfo: "",
        },
        android: {
          memory: "",
          storageSpace: "",
          macAddress: "",
          serialNum: "",
          androidVersion: "",
        },
      },
      noData: true,
    };
  },
  props: ["serialNum", "buildingName", "classroomName"],
  watch: {
    value1(val, oldVal) {
      this.value1 = val;
      setTimeout(() => {
        this.myCharts1();
      });
    },
    value2(val, oldVal) {
      this.value2 = val;
      setTimeout(() => {
        this.myCharts2();
      });
    },
    value3(val, oldVal) {
      this.value3 = val;
      setTimeout(() => {
        this.myCharts3();
      });
    },
  },
  methods: {
    myCharts1() {
      let myChart = echarts.init(this.$refs.cpu);
      let option = {
        series: [
          {
            type: "liquidFill",
            radius: "95%",
            waveAnimation: true,
            data: [
              {
                value: this.value1,
                direction: "left",
                itemStyle: {
                  normal: {
                    color: eval(
                      "if(this.value1<0.3){'rgba(0, 159, 232, 1)'}else if(this.value1<0.7){'rgba(250, 173, 20, 1)'}else{'rgba(248, 9, 65, 1)'}"
                    ),
                  },
                },
              },
            ],
            outline: {
              //   show: true, //是否显示轮廓 布尔值
              borderDistance: 1, // 外部轮廓与图表的距离 数字
              itemStyle: {
                borderColor: eval(
                  "if(this.value1<0.3){'rgba(0, 159, 232, 1)'}else if(this.value1<0.7){'rgba(250, 173, 20, 1)'}else{'rgba(248, 9, 65, 1)'}"
                ), // 边框的颜色
                borderWidth: 3, // 边框的宽度
                // shadowBlur: 5 , //外部轮廓的阴影范围 一旦设置了内外都有阴影
                // shadowColor: '#000' //外部轮廓的阴影颜色
              },
            },
            itemStyle: {
              opacity: 0.9, // 波浪的透明度
              shadowBlur: 0, // 波浪的阴影范围
            },
            backgroundStyle: {
              color: "#fff", // 图表的背景颜色
            },
            label: {
              // 数据展示样式
              show: true,
              color: "#000",
              insideColor: "#fff",
              fontSize: 20,
              fontWeight: 400,
              align: "center",
              baseline: "middle",
              position: "inside",
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    myCharts2() {
      let myChart = echarts.init(this.$refs.ram);
      let option = {
        series: [
          {
            type: "liquidFill",
            radius: "95%",
            waveAnimation: true,
            data: [
              {
                value: this.value2,
                direction: "left",
                itemStyle: {
                  normal: {
                    color: eval(
                      "if(this.value2<0.3){'rgba(0, 159, 232, 1)'}else if(this.value2<0.7){'rgba(250, 173, 20, 1)'}else{'rgba(248, 9, 65, 1)'}"
                    ),
                  },
                },
              },
            ],
            outline: {
              //   show: true, //是否显示轮廓 布尔值
              borderDistance: 1, // 外部轮廓与图表的距离 数字
              itemStyle: {
                borderColor: eval(
                  "if(this.value2<0.3){'rgba(0, 159, 232, 1)'}else if(this.value2<0.7){'rgba(250, 173, 20, 1)'}else{'rgba(248, 9, 65, 1)'}"
                ), // 边框的颜色
                borderWidth: 3, // 边框的宽度
                // shadowBlur: 5 , //外部轮廓的阴影范围 一旦设置了内外都有阴影
                // shadowColor: '#000' //外部轮廓的阴影颜色
              },
            },
            itemStyle: {
              opacity: 0.9, // 波浪的透明度
              shadowBlur: 0, // 波浪的阴影范围
            },
            backgroundStyle: {
              color: "#fff", // 图表的背景颜色
            },
            label: {
              // 数据展示样式
              show: true,
              color: "#000",
              insideColor: "#fff",
              fontSize: 20,
              fontWeight: 400,
              align: "center",
              baseline: "middle",
              position: "inside",
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    myCharts3() {
      let myChart = echarts.init(this.$refs.hardDisk);
      let option = {
        series: [
          {
            type: "liquidFill",
            radius: "95%",
            waveAnimation: true,
            data: [
              {
                value: this.value3,
                direction: "left",
                itemStyle: {
                  normal: {
                    color: eval(
                      "if(this.value3<0.3){'rgba(0, 159, 232, 1)'}else if(this.value3<0.7){'rgba(250, 173, 20, 1)'}else{'rgba(248, 9, 65, 1)'}"
                    ),
                  },
                },
              },
            ],
            outline: {
              //   show: true, //是否显示轮廓 布尔值
              borderDistance: 1, // 外部轮廓与图表的距离 数字
              itemStyle: {
                borderColor: eval(
                  "if(this.value3<0.3){'rgba(0, 159, 232, 1)'}else if(this.value3<0.7){'rgba(250, 173, 20, 1)'}else{'rgba(248, 9, 65, 1)'}"
                ), // 边框的颜色
                borderWidth: 3, // 边框的宽度
                // shadowBlur: 5 , //外部轮廓的阴影范围 一旦设置了内外都有阴影
                // shadowColor: '#000' //外部轮廓的阴影颜色
              },
            },
            itemStyle: {
              opacity: 0.9, // 波浪的透明度
              shadowBlur: 0, // 波浪的阴影范围
            },
            backgroundStyle: {
              color: "#fff", // 图表的背景颜色
            },
            label: {
              // 数据展示样式
              show: true,
              color: "#000",
              insideColor: "#fff",
              fontSize: 20,
              fontWeight: 400,
              align: "center",
              baseline: "middle",
              position: "inside",
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //返回
    back() {
      this.$router.go(-1);
    },
    //关机
    off() {
      this.$confirm({
        title: "您确认关机吗",
        onOk: () => {
          console.log("发送关机指令");
          let userInfo = this.$ls.get("userInfo");
          let obj3 = {
            action: 2,
            extand: "user",
            chatMsg: {
              senderId: userInfo.id,
              msg: "关机",
              msgType: 1,
              data: { devices: [] },
            },
          };
          obj3.chatMsg.data.devices.push(this.serialNum);
          console.log(obj3);
          this.websocket.send(JSON.stringify(obj3));
        },
      });
    },
    //重启
    reon() {
      this.$confirm({
        title: "您确认重启吗",
        onOk: () => {
          console.log("发送重启指令");
          let userInfo = this.$ls.get("userInfo");
          let obj3 = {
            action: 2,
            extand: "user",
            chatMsg: {
              senderId: userInfo.id,
              msg: "重启",
              msgType: 3,
              data: { devices: [] },
            },
          };
          obj3.chatMsg.data.devices.push(this.serialNum);
          console.log(obj3);
          this.websocket.send(JSON.stringify(obj3));
        },
      });
    },
    //息屏
    sleep() {
      this.$confirm({
        title: "您确认息屏吗",
        onOk: () => {
          console.log("发送息屏指令");
          let userInfo = this.$ls.get("userInfo");
          let obj3 = {
            action: 2,
            extand: "user",
            chatMsg: {
              senderId: userInfo.id,
              msg: "息屏",
              msgType: 2,
              data: { devices: [] },
            },
          };
          obj3.chatMsg.data.devices.push(this.serialNum);
          console.log(obj3);
          this.websocket.send(JSON.stringify(obj3));
        },
      });
    },
    //亮屏
    light() {
      this.$confirm({
        title: "您确认亮屏吗",
        onOk: () => {
          console.log("发送亮屏指令");
          let userInfo = this.$ls.get("userInfo");
          let obj3 = {
            action: 2,
            extand: "user",
            chatMsg: {
              senderId: userInfo.id,
              msg: "亮屏",
              msgType: 19,
              data: { devices: [] },
            },
          };
          obj3.chatMsg.data.devices.push(this.serialNum);
          console.log(obj3);
          this.websocket.send(JSON.stringify(obj3));
        },
      });
    },
    //检测升级
    checkUpdate() {
      console.log(111);
    },
    //webscoket 初始化
    initWebSocket() {
      const wsurl = this.$globalVariable.wsurl; //ws 相当于http 而wss 相当于https
      this.websocket = new WebSocket(wsurl); //实例对象
      this.websocket.onmessage = this.websocketonmessage;
      this.websocket.onopen = this.websocketonopen;
      this.websocket.onerror = this.websocketonerror;
      this.websocket.onclose = this.websocketclose;
    },
    //连接建立
    websocketonopen() {
      console.log("前端连接建立成功");
      //连接建立之后执行send方法发送数据
      this.websocketsendFirst();
      this.websocketsendSecond();
      this.timer = setInterval(() => {
        this.doSend();
      }, 30 * 1000);
    },
    //websocket心跳  防连接超时  WebSocket规定在一定时间内没有数据交互，就会自动断开
    doSend() {
      console.log("发送心跳");
      this.websocketsendSecond();
    },
    //首次发送数据 建立连接
    websocketsendFirst() {
      console.log("首次向后端发送数据");
      let userInfo = this.$ls.get("userInfo");
      let obj1 = {
        action: 1,
        extand: "user",
        chatMsg: {
          senderId: userInfo.id,
          receiverId: "",
          msg: "建立连接",
          msgType: 0,
        },
      };
      this.websocket.send(JSON.stringify(obj1)); //发送数据，传什么数据由实际需求决定
    },
    //查看设备状态
    websocketsendSecond() {
      console.log("查询设备状态");
      let userInfo = this.$ls.get("userInfo");
      let obj2 = {
        action: 2,
        extand: "user",
        chatMsg: {
          senderId: userInfo.id,
          msg: "查询设备状态",
          msgType: 8,
          data: { devices: [] },
        },
      };
      obj2.chatMsg.data.devices.push(this.serialNum);
      // console.log(JSON.stringify(obj2));
      this.websocket.send(JSON.stringify(obj2));
    },
    //数据接收
    websocketonmessage(e) {
      console.log("接收后端返回数据");
      var result = JSON.parse(e.data);
      console.log(result);
      if (result.msg === "返回设备详情") {
        if (result.data) {
          if (result.data.windows == null) {
            this.obj.android = result.data.android;
          } else {
            this.obj = result.data;
            this.noData = false;
            this.value1 = parseInt(result.data.windows.cpuUsed) / 100;
            this.value2 = parseInt(result.data.windows.memoryUsed) / 100;
            this.value3 = parseInt(result.data.windows.diskUsed) / 100;
          }
        }
      } else if (result.msg === "您要访问的终端已离线") {
        this.$message.warning("您当前访问的终端已离线");
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      }
    },
    //连接建立失败重连
    websocketonerror() {
      this.initWebSocket();
    },
    //连接关闭
    websocketclose(e) {
      console.log("websocket断开连接");
      clearInterval(this.timer);
    },
  },
  mounted() {
    setTimeout(() => {
      this.myCharts1();
      this.myCharts2();
      this.myCharts3();
    });
  },
  created() {
    this.initWebSocket();
  },
  destroyed() {
    this.websocketclose();
  },
  //在页面离开时做的操作
  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
};
</script>

<style lang="scss" scoped src="./dev-detail.scss"></style>
